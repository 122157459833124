import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IntegrationsComponent } from '@modules/settings/integrations/integrations.component';

const routes: Routes = [
  {
    path: '',
    component: IntegrationsComponent
  }
];

export const INTEGRATION_ROUTES = [
  {
    path: 'settings/integrations/github',
    title: 'Integrations: GitHub',
    loadChildren: () => import('@modules/settings/integrations/scm-platform/scm-platform.module').then(m => m.ScmPlatformModule),
    data: { preload: true },
  },
  {
    path: 'settings/integrations/gitlab',
    title: 'Integrations: GitLab',
    loadChildren: () => import('@modules/settings/integrations/scm-platform/scm-platform.module').then(m => m.ScmPlatformModule),
    data: { preload: true },
  },
  {
    path: 'settings/integrations/jira',
    title: 'Integrations: Jira',
    loadChildren: () => import('@modules/settings/integrations/task-platform/task-platform.module').then(m => m.TaskPlatformModule),
    data: { preload: true },
  },
  {
    path: 'settings/integrations/circleci',
    title: 'Integrations: CircleCI',
    loadChildren: () => import('@modules/settings/integrations/cicd-platform/cicd-platform.module').then(m => m.CicdPlatformModule),
    data: { preload: true },
  },
  {
    path: 'settings/integrations/github-actions',
    title: 'Integrations: GitHub Actions',
    loadChildren: () => import('@modules/settings/integrations/cicd-platform/cicd-platform.module').then(m => m.CicdPlatformModule),
    data: { preload: true },
  },
  {
    path: 'settings/integrations/gitlab-pipeline',
    title: 'Integrations: GitLab Pipeline',
    loadChildren: () => import('@modules/settings/integrations/cicd-platform/cicd-platform.module').then(m => m.CicdPlatformModule),
    data: { preload: true },
  },
  {
    path: 'settings/integrations/service-now',
    title: 'Integrations: ServiceNow',
    loadChildren: () => import('@modules/settings/integrations/itsm-platform/itsm-platform.module').then(m => m.ItsmPlatformModule),
    data: { preload: true },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IntegrationsRoutingModule {
}
